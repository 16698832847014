import { render, staticRenderFns } from "./BulletDescription.vue?vue&type=template&id=598b2158&scoped=true"
import script from "./BulletDescription.vue?vue&type=script&lang=js"
export * from "./BulletDescription.vue?vue&type=script&lang=js"
import style0 from "./BulletDescription.vue?vue&type=style&index=0&id=598b2158&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "598b2158",
  null
  
)

export default component.exports